import React, { useState, useRef } from "react"
import Calculator from "./calculator"
import BonusSummary from "./calculator/bonus-summary"

export default props => {
  const [additionalPeople, setPeople] = useState(0)
  const [collapsed, setCollapsed] = useState([false])
  
  const [allBoni, setAllBoni] = useState([])
  const [allKkvBoni, setAllKkvBoni] = useState([])

  const [bonus, setBonus] = useState(0)

  const [age, setAge] = useState(props.age ? props.age : 0)

  function onHandleNewPerson() {
    let col = collapsed
    col[additionalPeople] = true
    setCollapsed(col)
    if (additionalPeople <= 4) {
      setPeople(additionalPeople + 1)
    }
  }

  function onChangeBonus(currentBonus, currentKkvBonus, index) {
    let temp = allBoni
    let tempKkv = allKkvBoni
    temp[index] = currentBonus
    tempKkv[index] = currentKkvBonus

    setBonus(temp.reduce((carry, val) => carry + val, 0) + tempKkv.reduce((carry, val) => carry + val, 0))
    setAllKkvBoni(tempKkv);
    setAllBoni(temp)
  }

  function newPerson() {
    let arr = []
    for (let it = 0; it < additionalPeople; it++) {
      arr.push(1)
    }
    return arr.map((x, i) => {
      return (
        <Calculator
          key={i}
          index={i + 1}
          onHandleNewPerson={onHandleNewPerson}
          insuranceType={props.insuranceType}
          collapse={collapsed[i + 1]}
          newPerson={true}
          onChangeBonus={(bon, kkvBonus) => {
            onChangeBonus(bon, kkvBonus, i+1)
          }}
          onAllInformation={info => {
            props.onAllInformation(info)
          }}
          informations={{}}
          header={false}
          additionalMemberCount={additionalPeople}
          kkv={props.kkv}
          beitragsmodul={props.beitragsmodul}
        />
      )
    })
  }

  return (
    <>
      <Calculator
        index={0}
        onHandleNewPerson={onHandleNewPerson}
        insuranceType={props.insuranceType}
        collapse={collapsed[0]}
        age={age}
        newPerson={age === 0 ? true : false}
        onChangeBonus={(bon, kkvBonus) => {
          onChangeBonus(bon, kkvBonus, 0)
        }}
        onAllInformation={info => {
          props.onAllInformation(info);
        }}
        informations={props.informations[additionalPeople]}
        header={true}
        additionalMemberCount={additionalPeople}
        kkv={props.kkv}
        beitragsmodul={props.beitragsmodul}
        questionnaireData={props.questionnaireData}
      />
      {additionalPeople > 0 && newPerson()}
      {bonus > 0 && <BonusSummary bonus={(bonus)} />}
    </>
  )
}
