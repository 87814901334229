import React, { useRef, useState } from "react"

import PageHeader from "./my-insturance-features/header"
import Benefits from "./my-insturance-features/benefits"
import Calculator from "../../components/content-element/calculator-module"
import Questionnaire from "./my-insturance-features/questionnaire"

import * as service from "../../services/auth"
import OfferForm from "../../components/forms/offer"
import moment from "moment"

const MyInsurance = ({ data }) => {
  const page = {
    seo: {
      title: "Meine Leistungen",
      description: "Meine Leistungen",
    },
  }

  const kkv = data.allDatoCmsKkv.nodes
  // beitragsmodul where gueltigAb is in past and gueltigBis is in future or gueltigBis is undefined
  const beitragsmodul = data.allDatoCmsBeitragsmodul.nodes.find(
    f =>
      (moment(new Date(f.gueltigAb)).isBefore(new Date()) &&
        moment(new Date(f.gueltigBis)).isAfter(new Date())) ||
      f.gueltigBis === null
  )

  const [selectedPhases, setSelectedPhases] = useState(null)
  const [benefits, setBenefits] = useState(null)
  const [age, setAge] = useState(0)
  const [questionnaireData, setQuestionnaireData] = useState({})
  const [informations, setInformations] = useState([{}])
  const [offerInformations, setOfferInformations] = useState({})
  const [phaseId, setPhaseId] = useState()

  const calculatorRef = useRef()

  const phases = data.allDatoCmsLebensphasen.nodes.map(p => {
    p.altersgruppe = p.altersgruppe.map(a => {
      a.id = a.id.replace(/DatoCmsAltersgruppe-/, "")
      return a
    })
    p.versicherungsart = p.versicherungsart.map(v => {
      v.id = v.id.replace(/DatoCmsVersicherungsart-/, "")
      return v
    })
    p.mitgliedschaftsart = p.mitgliedschaftsart.map(m => {
      m.id = m.id.replace(/DatoCmsMitgliedschaftsarten-/, "")
      return m
    })
    p.score =
      (p.altersgruppe || []).length +
      (p.versicherungsart || []).length +
      (p.mitgliedschaftsart || []).length
    return p
  })

  const onSubmit = (reset) => {
  };

  const onQuestionnaireResult = result => {
    // age
    setAge(result.age.alt)

    setQuestionnaireData({
      customer: result.customer.value,
      occupation: result.occupation.value,
      age: result.age.alt,
      ageID: result.age.value,
      birthday: result.birthday.value,
    })

    // benefits
    const validPhases = phases
      .filter(phase => {
        // mitgliedschaftsart
        if (
          phase.mitgliedschaftsart.filter(m => m.id === result.customer.value)
            .length === 0
        )
          return false
        // altersgruppe
        if (
          phase.altersgruppe.filter(a => a.id === result.age.value).length === 0
        )
          return false
        // versicherungsart
        return (
          phase.versicherungsart.filter(m => m.id === result.occupation.value)
            .length > 0
        )
      })
      // sort by score, low to high
      .sort((a, b) => a.score - b.score)

    // select only 1 phase
    const selected = validPhases.length > 0 ? validPhases[0] : null
    if (!selected) {
      return
    }

    setPhaseId(selected.originalId)

    // reset calculators
    setBenefits(null)

    // set benefits after X milliseconds
    if (service.isBrowser()) {
      window.setTimeout(() => {
        setBenefits(selected ? selected.leistungen : [])
        if (process.env.GATSBY_DEBUG_INSURANCEFEATURES_PHASES === "1") {
          setSelectedPhases(validPhases.map(p => p.name))
        }
      }, 250)
    }
  }

  return (
    <>
      <PageHeader page={page} />

      <Questionnaire onResult={onQuestionnaireResult} />

      {/* {selectedPhases && (
        <ul
          style={{
            listStyleType: "none",
            padding: "32px",
            background: "yellow",
          }}
        >
          <li>
            <strong>Debug: Lebensphase</strong>
          </li>
          {selectedPhases.map((n, i) => (
            <li key={i}>- {n.originalId}</li>
          ))}
        </ul>
      )} */}

      {benefits != null && (
        <>
          <Benefits
            items={benefits}
            onCalcClick={() => {
              if (!calculatorRef.current) return
              window.scrollTo({
                behavior: "smooth",
                top: calculatorRef.current.offsetTop + 30,
              })
            }}
          />

          {benefits.length > 0 && (
            <>
              <div ref={calculatorRef}>
                <Calculator
                  age={age}
                  beitragsmodul={beitragsmodul}
                  kkv={kkv}
                  insuranceType={data.allDatoCmsVersicherungsart.nodes}
                  onAllInformation={info => {
                    let temp = informations
                    let index = informations.findIndex(f => f.id === info.id)
                    if (index >= 0) {
                      temp[index] = info
                    } else {
                      temp.push(info)
                    }
                    setInformations(temp)
                  }}
                  informations={informations}
                  questionnaireData={questionnaireData}
                />
              </div>
            </>
          )}
          <OfferForm
            onAllOfferInformation={info => {
              setOfferInformations(info)
            }}
            onSubmit={onSubmit}
            data={{
              age: age,
              bonus: informations,
              ...questionnaireData,
            }}
            phaseId={phaseId}
          />
        </>
      )}
    </>
  )
}

export default MyInsurance
