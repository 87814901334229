// extracted by mini-css-extract-plugin
export var calcSection = "component-module--calcSection--1LsCw";
export var headerContainer = "component-module--headerContainer--3ujze";
export var bonus = "component-module--bonus--2rqE2";
export var head = "component-module--head--n1rAd";
export var bonusCalculator = "component-module--bonusCalculator--2INwH";
export var header = "component-module--header--2xSjm";
export var textWrap = "component-module--textWrap--1ImVY";
export var h2 = "component-module--h2--1sU8N";
export var h4 = "component-module--h4--2_J6I";
export var bonusResult = "component-module--bonusResult--3n1Xo";
export var collapsed = "component-module--collapsed--21XO1";
export var h = "component-module--h--1CqV-";
export var bon = "component-module--bon--yyCvL";
export var num = "component-module--num--1Mz3_";
export var innerCalc = "component-module--innerCalc--2chWP";
export var noMargin = "component-module--noMargin--2MFzX";
export var item = "component-module--item--1_wUd";
export var left = "component-module--left--3C7Eg";
export var selects = "component-module--selects--1y6_6";
export var selectWrap = "component-module--selectWrap--3RAB6";
export var input = "component-module--input--3Hl21";
export var optionContainer = "component-module--option-container--2yGqF";
export var circle = "component-module--circle--2dHrJ";
export var text = "component-module--text--1bdl_";
export var img = "component-module--img--3_Lyf";
export var blue = "component-module--blue--3YrUL";
export var green = "component-module--green--2ginI";
export var title = "component-module--title--1doYV";
export var rightEnd = "component-module--rightEnd--1_jCf";
export var footer = "component-module--footer--11r9_";
export var kkvBonus = "component-module--kkvBonus--pTdo6";
export var hint = "component-module--hint--s9r4d";
export var tagsContainer = "component-module--tagsContainer--2wILh";
export var tag = "component-module--tag--3NcMA";
export var active = "component-module--active--20r8p";
export var checkbox = "component-module--checkbox--2v8HP";
export var nextPerson = "component-module--nextPerson--3jwOI";
export var bmiContainer = "component-module--bmiContainer--3Hd4v";
export var checkboxBMI = "component-module--checkboxBMI--144tZ";
export var checkboxVac = "component-module--checkboxVac--2f8vx";
export var checkboxV = "component-module--checkboxV--33e50";
export var selectWrapper = "component-module--selectWrapper--12DvT";
export var buttonDown = "component-module--buttonDown--hNYB0";
export var buttonUp = "component-module--buttonUp--1Iqzz";
export var select = "component-module--select--2Sd7u";
export var overall = "component-module--overall--3iptx";