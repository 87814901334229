import React from "react"

import * as styles from "./component.module.less"

export default props => {
  return (
    <div className={styles.fcard}>
      {props.title && (
        <div className={`${styles.container} h2 ${styles.title}`}>
          {props.title}
        </div>
      )}
      {props.titleHtml && (
        <div
          className={`${styles.container} h2 ${styles.title}`}
          dangerouslySetInnerHTML={{ __html: props.titleHtml }}
        />
      )}
      <div className={styles.container}>{props.children}</div>
    </div>
  )
}
