import React, { useEffect, useState } from "react"

import ContentContainer from "../../../../../components/content-element/container"

import * as styles from "./component.module.less"
import * as service from "../../../../../services/auth"

export default props => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    service.isBrowser() ? setWindowWidth(window.innerWidth) : setWindowWidth(0)
  }, [window.innerWidth])

  return (
      <ContentContainer tag="section" className={styles.container}>
        <div className={styles.text}>
          Jetzt Mitglied werden und profitieren
          {/* vom FitBonus<sup>+</sup>  */}
        </div>
        <div className={styles.bonus}>
          {/* {windowWidth <= 900 && (
                    <div>
                    Gesamt
                    </div>
                )} */}
          {props.bonus
            .toFixed(2)
            .split(".")
            .join(",")}
          {" €"}
        </div>
      </ContentContainer>
  )
}
