import React from "react"

import { GlobalContext } from "../../context/global"

import SelectCfe from "../../elements/controls/select"

import * as styles from "./component.module.less"

export const FieldRenderer = (
  idPrefix,
  name,
  type,
  label,
  placeholder,
  ref,
  error,
  value,
  maxLength = "",
) => (
  <div className={styles.field}>
    <label htmlFor={idPrefix + "-" + name}>{label}</label>
    {type === "textarea" && (
      <textarea
        className={error ? "error" : ""}
        name={name}
        id={idPrefix + "-" + name}
        placeholder={placeholder}
        ref={ref}
      >{value}</textarea>
    )}
    {type !== "textarea" && (
      <input
        className={error ? "error" : ""}
        type={type}
        name={name}
        id={idPrefix + "-" + name}
        placeholder={placeholder}
        maxLength={`${maxLength}`}
        ref={ref}
        defaultValue={value}
      />
    )}
    {ErrorRenderer(error)}
  </div>
)

export const ErrorRenderer = error => {
  if (!error) {
    return null
  }

  if (error && (!error.message || error.message.length === 0)) {
    switch (error.type) {
      case "required":
        error.message = "Diese Eingabe ist ein Pflichtfeld."
        break
      default:
        error.message = "Bitte überprüfen Sie ihre Eingabe."
    }
  }

  return <div className="form-error">{error.message}</div>
}

export const SalutationFieldRenderer = (
  idPrefix,
  label,
  cfeRef,
  formRef,
  error,
  value,
  name = "salutation"
) => (
  <div className={styles.field}>
    <label htmlFor={idPrefix + "-" + name}>{label}</label>
    <SelectCfe
      id={idPrefix + "-" + name}
      name={name}
      ref={cfeRef}
      formElementRef={formRef}
      error={error}
      defaultValue={value}
      label={value}
    >
      <option value={""} selected={value === "" ? "selected" : ""}>bitte wählen</option>
      <option value={"Herr"} selected={value === "Herr" ? "selected" : ""}>Herr</option>
      <option value={"Frau"} selected={value === "Frau" ? "selected" : ""}>Frau</option>
      <option value={"Divers"} selected={value === "Divers" ? "selected" : ""}>Divers</option>
      <option value={"Unbestimmt"} selected={value === "Unbestimmt" ? "selected" : ""}>Unbestimmt</option>
    </SelectCfe>
    {ErrorRenderer(error)}
  </div>
)

export const Legend = (title, subtitle = null) => (
  <>
    {title && title.length > 0 && (
      <legend className={`h1 ${styles.legend}`}>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {subtitle && (
          <span
            className={`h4 ${styles.sub}`}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </legend>
    )}
  </>
)

export const Honeypot = ref => (
  <input
    aria-hidden="true"
    className="visual-hide"
    name="emailReminderAddress"
    type="text"
    ref={ref}
  />
)

export const RequiredFieldsInfo = () => (
  <p className={`required-info`}>
    Alle mit Sternchen* markierten Felder müssen korrekt ausgefüllt werden.
  </p>
)

export const Success = (
  reset = null,
  title = null,
  txt = null,
  backTitle = null,
  backUrl = null,
  prevTitle = null
) => {
        return (
            <div className={styles.success}>
                <p className={`h1`}>
                    {title && <>{title}</>}
                    {title == null && <>Ihr Formular wurde erfolgreich versendet.</>}
                </p>
                {txt && <p className={styles.txt}>{txt}</p>}

                <div className={styles.btns}>
                        <button
                            onClick={() => {
                                window.location.reload(false);
                            } }
                            className={`btn block em icon icon-pos-rhs icon-next-w`}
                        >
                            {backTitle != null && <>{backTitle}</>}
                            {backTitle == null && <>weiter</>}
                        </button>

                    {reset != null && (
                        <button
                            onClick={() => {
                                reset()
                            } }
                            className={`btn outline icon icon-prev-b`}
                        >
                            {prevTitle ? prevTitle : "f"}
                        </button>
                    )}
                </div>
            </div>
        )
    }

export default props => {
  let context

  const onSubmit = e => {
    const handler = props.onSubmit || (() => {})
    handler(
      {
        appUrl: process.env.GATSBY_APIOMAT_APP_WEBFORMS_BASEURL,
        key: process.env.GATSBY_APIOMAT_APP_WEBFORMS_KEY,
        systemId: process.env.GATSBY_APIOMAT_APP_WEBFORMS_SYSTEMID,

        appPostOfficeUrl: process.env.GATSBY_APIOMAT_APP_POSTOFFICE_BASEURL,
        appPostOfficeKey: process.env.GATSBY_APIOMAT_APP_POSTOFFICE_KEY,
        appPostOfficeSystemId: process.env.GATSBY_APIOMAT_APP_POSTOFFICE_SYSTEMID,
        appPostOfficeAppName: process.env.GATSBY_APIOMAT_APP_POSTOFFICE_APPNAME,
      },
      context,
      e
    )
  }

  return (
    <GlobalContext.Consumer>
      {ctx => {
        context = ctx
        return (
          <>
            {props.submitted &&
              Success(
                () => {
                  props.setSubmitted && props.setSubmitted(false)
                },
                props.submittedTitle,
                props.submittedText,
                props.backTitle,
                props.backUrl,
                props.prevTitle
              )}

            {!props.submitted && (
              <form
                ref={props.formRef || null}
                className={`${props.className || ""}`}
                onSubmit={onSubmit}
                onReset={props.onReset}
                noValidate
              >
                {Legend(props.legend, props.subLegend)}
                {props.children}
              </form>
            )}
          </>
        )
      }}
    </GlobalContext.Consumer>
  )
}
