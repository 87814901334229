import React, { useEffect, useState } from "react"

import ContentContainer from "../../container"
import ChooseOption from "./choose-option"
import FitBonus from "../../../../lib/service/fit-bonus"
import Activities from "./activities"
import Bonus from "./bonus"
import Select from "../../../elements/controls/select"

import * as styles from "./component.module.less"

import generali from "../../../../../static/assets/generali.png"
import men from "../../../../../static/assets/men.svg"
import women from "../../../../../static/assets/women.svg"
import LayoutTemplate from "./layout-template"

export default props => {
  const [step, setStep] = useState(props.newPerson ? -1 : 1)
  const [activities, setActivities] = useState([])
  const [informations, setInformations] = useState(props.informations) // hier kommen informationen an falls welche reingegeben werden.
  const [currentBonus, setCurrentBonus] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [age, setAge] = useState(props.age)
  const [collapse, setCollapse] = useState(props.collapse)
  const [currentName, setCurrentName] = useState(
    `Person ${props.additionalMemberCount + 1}`
  )
  const [kkv, setKKv] = useState(null)
  const [kkvBonus, setKkvBonus] = useState(0)
  const [brutto, setBrutto] = useState(0)
  const [insuranceType, setInsuranceType] = useState(
    props.questionnaireData ? props.questionnaireData.occupation : ""
  )
  const [hint, setHint] = useState("")

  useEffect(() => {
    setAge(props.age)
    setStep(props.newPerson ? -1 : 1)
    setActivities([])
    setCurrentBonus(0)
    setDisabled(true)
  }, [props.age, props.newPerson])

  const fitBonusService = new FitBonus(
    process.env.GATSBY_APIOMAT_MODULE_FITBONUS_BASEURL,
    process.env.GATSBY_APIOMAT_MODULE_FITBONUS_KEY,
    process.env.GATSBY_APIOMAT_MODULE_FITBONUS_SYSTEMID
  )

  let insuranceT = props.insuranceType.sort((a, b) =>
    a.nameAuswahlMeineLeistungen.localeCompare(b.nameAuswahlMeineLeistungen)
  )

  let i = 0
  let fallback = insuranceT.find((a, index) => {
    i = index
    return a.id === "DatoCmsVersicherungsart-89709865-de"
  })
  insuranceT.splice(i, 1)
  insuranceT.push(fallback)

  const calculateBonus = userData => {
    const bonus = Object.keys(userData).reduce((total, key) => {
      const activ = activities.find(
        f => f.name.trim().toLowerCase() === key.trim().toLowerCase()
      )
      if (!activ) return total

      const activKeys = Object.keys(activ.refundPerActivity)
      if (activKeys.length === 0) return total

      // bool
      if (userData[key] === true) {
        return total + Number(activ.refundPerActivity[activKeys[0]])
      }

      // array
      if (Array.isArray(userData[key])) {
        const len = Object.keys(activ.refundPerActivity).length
        return (
          total +
          userData[key].reduce((infoTotal, info, index) => {
            if (index + 1 > len)
              return infoTotal + Number(activ.refundPerActivity[len])
            return infoTotal + Number(activ.refundPerActivity[index + 1])
          }, 0)
        )
      }

      // bmi
      if (key === "bmi" && userData.bmi === true) {
        return total + Number(activ.refundPerActivity[activKeys[0]])
      }

      // selected item
      if (activKeys.includes(userData[key])) {
        return total + Number(activ.refundPerActivity[userData[key]])
      }

      return total
    }, 0)

    setCurrentBonus(bonus)
    return bonus
  }

  const handlePrevStep = () => {
    let prevStep
    if (step > 0) {
      prevStep = step - 1
    }
    if (Number(age) < 18 && step === 2) {
      prevStep = 0
    }
    if (prevStep !== undefined) {
      if (prevStep === 1 || prevStep === 2) {
        setKKv(null)
        setKkvBonus(0)
      }
      setStep(prevStep)
    }
  }

  const boniCalc = (brutto, oldZusatz, newZusatz) => {
    return (brutto * oldZusatz) / 100 - (brutto * newZusatz) / 100
  }

  const handleNextStep = userData => {
    const _setAct = activityData => {
      const processedActivityData = activityData
        .sort((a, b) => a.orderPosition - b.orderPosition)
        .map(a => {
          if (!Array.isArray(a.activityDependencies)) {
            a.activityDependencies = []
          }
          a.activityDependencies = a.activityDependencies
            .map(dep => activityData.find(other => other.id === dep.id))
            .filter(dep => dep !== undefined)
            .map(dep => {
              return { id: dep.id, expectedValue: true }
            })
          a.enabled = true
          return a
        })

      setActivities(processedActivityData)
    }

    if (!userData) {
      userData = JSON.parse(JSON.stringify(informations))
    }

    if (step === 0) {
      if (insuranceType === "143688725-de") {
        // familienversichert
        setStep(3)
        return
      }
    }

    setDisabled(true)
    if (step === 2) {
      if (kkv !== null) {
        if (
          insuranceType === "89709799-de" // Arbeitnehmer
        ) {
          let tempBrutto = brutto
          if (brutto > props.beitragsmodul.bbgKvPvJahr) {
            tempBrutto = props.beitragsmodul.bbgKvPvJahr
            setHint(
              "Zur Berechnung wurde der gesetzlich festgelegte Höchstsatz (" +
                props.beitragsmodul.bbgKvPvJahr +
                "€) verwendet."
            )
          } else if (brutto < props.beitragsmodul.gehaltMinJahr) {
            tempBrutto = props.beitragsmodul.gehaltMinJahr
            setHint(
              "Zur Berechnung wurde der gesetzlich festgelegte Mindestsatz (" +
                props.beitragsmodul.gehaltMinJahr +
                "€) verwendet."
            )
          }
          let boni = boniCalc(
            tempBrutto,
            0.5 * props.kkv.find(f => f.id === kkv).zusatzbeitrag,
            0.5 *
              props.kkv.find(f => f.id === "DatoCmsKkv-89709816-de")
                .zusatzbeitrag
          )

          setKkvBonus(boni)
        } else if (
          insuranceType === "89709801-de" || // Rentner
          insuranceType === "89709803-de" || // Arbeitssuchend
          insuranceType === "89709802-de" // Auszubildender
        ) {
          if (brutto > 0) {
            let boni = boniCalc(
              brutto,
              0.5 * props.kkv.find(f => f.id === kkv).zusatzbeitrag,
              0.5 *
                props.kkv.find(f => f.id === "DatoCmsKkv-89709816-de")
                  .zusatzbeitrag
            )
            setKkvBonus(boni)
          }
        } else if (
          insuranceType === "143610206-de" || // nicht erwerbstätig
          insuranceType === "89709804-de" || // Selbstständig
          insuranceType === "143688712-de" // Beamter
        ) {
          let tempBrutto = brutto
          if (brutto > props.beitragsmodul.bbgKvPvJahr) {
            tempBrutto = props.beitragsmodul.bbgKvPvJahr
            setHint(
              "Zur Berechnung wurde der gesetzlich festgelegte Höchstsatz (" +
                props.beitragsmodul.bbgKvPvJahr +
                "€) verwendet."
            )
          } else if (brutto < props.beitragsmodul.mbgKvPvJahr) {
            tempBrutto = props.beitragsmodul.mbgKvPvJahr
            setHint(
              "Zur Berechnung wurde der gesetzlich festgelegte Mindestsatz (" +
                props.beitragsmodul.mbgKvPvJahr +
                "€) verwendet."
            )
          }
          let boni = boniCalc(
            tempBrutto,
            props.kkv.find(f => f.id === kkv).zusatzbeitrag,
            props.kkv.find(f => f.id === "DatoCmsKkv-89709816-de").zusatzbeitrag
          )
          setKkvBonus(boni)
        } else if (insuranceType === "89709800-de") {
          // Student
          let boni = boniCalc(
            props.beitragsmodul.bafoegJahr,
            props.kkv.find(f => f.id === kkv).zusatzbeitrag,
            props.kkv.find(f => f.id === "DatoCmsKkv-89709816-de").zusatzbeitrag
          )
          setHint(
            "Zur Berechnung wurde der aktuelle BAföG Bedarfsatz (" +
              props.beitragsmodul.bafoegJahr +
              "€) verwendet"
          )
          setKkvBonus(boni)
        } else if (insuranceType === "89709805-de") {
          // Freiwillig versicherte Arbeitnehmer
          let tempBrutto = props.beitragsmodul.bbgKvPvJahr
          let boni = boniCalc(
            tempBrutto,
            0.5 * props.kkv.find(f => f.id === kkv).zusatzbeitrag,
            0.5 *
              props.kkv.find(f => f.id === "DatoCmsKkv-89709816-de")
                .zusatzbeitrag
          )
          setKkvBonus(boni)
          setHint(
            "Zur Berechnung wurde der aktuelle Höchstsatz für Arbeitnehmer herangezogen (" +
              props.beitragsmodul.bbgKvPvJahr +
              " € )"
          )
        }
      }
    }
    if (step === 3) {
      if (Number(age) < 18) {
        fitBonusService
          .getBonus(userData.gender, Number(age), userData.vitality_app)
          .then(data => {
            if (data.activities !== undefined) {
              _setAct(data.activities)
            }
          })
          .catch(e => console.error(e))
        setStep(5)
        return
      }
    } else if (step === 4) {
      fitBonusService
        .getBonus(userData.gender, Number(age), userData.vitality_app)
        .then(data => {
          if (data.activities !== undefined) {
            _setAct(data.activities)
          }
        })
        .catch(e => console.error(e))
    }

    if (activities && activities.length > 0) {
      if (step + 1 >= 5 + activities.filter(a => a.enabled).length) {
        props.onAllInformation(informations)
      }
    }
    setStep(step + 1)
  }

  const ageOpts = []
  for (let i = 0; i <= 101; i++) {
    ageOpts.push({
      value: i.toString(),
      label: i <= 100 ? i.toString() : "100+",
    })
  }

  const resultCountDescriptor = () => {
    if (props.index === undefined || props.index === null || props.index > 9)
      return ""
    return [
      "erstes",
      "zweites",
      "drittes",
      "viertes",
      "fünftes",
      "sechstes",
      "siebtes",
      "achtes",
      "neuntes",
      "zehntes",
    ][props.index]
  }

  function handleChange(name, option, multiple, continueToNextStep = false) {
    // save user data
    // let userData = JSON.parse(JSON.stringify(informations))
    let userData = informations
    if (step === 3) {
      userData.currentKkvId = kkv || ""
      userData.currentKkvName =
        props.kkv.find(f => f.id === kkv)?.miamiName || ""
      userData.brutto = brutto
      userData.id = Math.round(new Date().getTime() / 1000)
      userData.gender = "w"
      if (option === "Männlich") {
        userData.gender = "m"
      }
      if (Number(age) < 18) {
        userData.vitality_app = false
      }
      setDisabled(false)
    } else if (step === 4) {
      userData.vitality_app = option === "Ja"
      setDisabled(false)
    } else {
      if (multiple) {
        if (userData[name] === undefined) {
          userData[name] = []
        }
        if (!userData[name].includes(option)) {
          userData[name].push(option)
        } else {
          let t = []
          userData[name].forEach(f => {
            if (f !== option) {
              t.push(f)
            }
          })
          userData[name] = t
        }
      } else {
        userData[name] =
          option === "Ja" ? true : option === "Nein" ? false : option
      }
    }
    if (props.newPerson) {
      userData.age = age
      userData.name = currentName
      userData.insuranceType = insuranceType
    }
    userData.kkvBonus = kkvBonus
    setInformations(userData)
    let bonus = calculateBonus(userData)
    userData.bonus = bonus
    props.onChangeBonus(bonus, kkvBonus)
    // update activities based on activity dependencies
    // currentIndex, currentId and currentValue reference the currently changed activity user input

    const currentIndex = activities.findIndex(a => a.name === name)

    if (currentIndex >= 0) {
      const currentId = activities[currentIndex].id
      const currentValue = userData[name]

      const updatedActivities = JSON.parse(JSON.stringify(activities)).map(
        (a, i) => {
          // iterate over all activities
          // iterated activities that come before the current activity are ignored
          //  since dependencies can not influence user input that has already been set
          if (i < currentIndex) return a

          // find a dependency that equals the current activity
          const dependencyIndex = a.activityDependencies.findIndex(
            dep => dep.id === currentId
          )
          if (dependencyIndex === -1) return a
          const dependency = a.activityDependencies[dependencyIndex]

          // if the dependency's expected value equals the value of the current activity
          //  the iterated activity is enabled and disabled otherwise
          a.activityDependencies[dependencyIndex].fulfilled =
            dependency.expectedValue === currentValue

          // the activity is considered "enabled" if any of it's dependencies is fulfilled
          a.enabled =
            a.activityDependencies.length > 0
              ? a.activityDependencies.filter(dep => dep.fulfilled === true)
                  .length > 0
              : true

          // if the iterated activity is disabled, it's user input is reset to "undefined"
          //  because the user might have already set a value for the iterated activity,
          //  returned to a previous activity and changed something
          if (!a.enabled) {
            userData[a.name] = undefined
          }

          return a
        }
      )
      setActivities(updatedActivities)
    }

    if (continueToNextStep) {
      handleNextStep(userData)
    }
  }

  function onChangeBmi(bmi, ok) {
    let tempInfo = JSON.parse(JSON.stringify(informations))
    tempInfo.bmi = ok
    setInformations(tempInfo)
    calculateBonus(tempInfo)
  }

  const allSteps = () => {
    switch (step) {
      case -1:
        return (
          <>
            {props.newPerson && (
              <>
                <div className={styles.innerCalc}>
                  <div className={styles.left}>
                    <div className={styles.header}>
                      <div className={styles.textWrap}>
                        <h4 className={styles.h4}>Name</h4>
                      </div>
                    </div>
                    <hr />
                    <input
                      onChange={val => setCurrentName(val.target.value)}
                      placeholder={currentName}
                    />
                    <div className={styles.header}>
                      <div className={styles.textWrap}>
                        <h4 className={styles.h4}>Mein Alter ist</h4>
                      </div>
                    </div>
                    <hr />
                    <div className={styles.selectWrapper}>
                      <Select
                        id="age"
                        name="age"
                        theme="light"
                        onChange={val => {
                          setAge(val)
                        }}
                      >
                        {ageOpts.map((o, i) => (
                          <option key={i} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <Bonus value={0} className={styles.rightEnd} />
                </div>
                <div className={styles.footer}>
                  <button
                    className={`btn block inverted icon icon-next icon-pos-rhs ${
                      styles.btn
                    } ${props.buttonDisabled ? styles.btnDisabled : ""}`}
                    onClick={handleNextStep}
                    style={{ zIndex: "10" }}
                  >
                    Weiter
                  </button>
                </div>
              </>
            )}
          </>
        )
      case 0:
        return (
          <>
            {props.newPerson && (
              <>
                <LayoutTemplate header={"Aktuell (oder bald) bin ich"}>
                  <Select
                    id="insuranceType"
                    name="insuranceType"
                    theme="light"
                    onChange={val => {
                      setInsuranceType(
                        val.replace(/DatoCmsVersicherungsart-/, "")
                      )
                    }}
                  >
                    <option value={""}>bitte wählen...</option>
                    {insuranceT.map((o, i) => (
                      <option key={i} value={o.id}>
                        {o.nameAuswahlMeineLeistungen}
                      </option>
                    ))}
                  </Select>
                </LayoutTemplate>
                <div className={styles.footer}>
                  <button
                    className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
                    onClick={handlePrevStep}
                  />
                  <button
                    className={`btn block inverted icon icon-next icon-pos-rhs ${
                      styles.btn
                    } ${props.buttonDisabled ? styles.btnDisabled : ""}`}
                    onClick={handleNextStep}
                    style={{ zIndex: "10" }}
                  >
                    Weiter
                  </button>
                </div>
              </>
            )}
          </>
        )
      case 1:
        return (
          <>
            <LayoutTemplate
              header={"Mein Jährliches Brutto-Gesamteinkommen in Euro beträgt"}
            >
              <input
                onChange={val => setBrutto(val.target.value)}
                placeholder={"Brutto-Gesamteinkommen"}
                style={{ marginTop: "0px" }}
                type={"number"}
              />
            </LayoutTemplate>
            <div className={styles.footer}>
              {props.newPerson === true && (
                <button
                  className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
                  onClick={handlePrevStep}
                />
              )}
              <button
                className={`btn block inverted icon icon-next icon-pos-rhs ${
                  styles.btn
                } ${props.buttonDisabled ? styles.btnDisabled : ""}`}
                onClick={handleNextStep}
                style={{ zIndex: "10" }}
              >
                Weiter
              </button>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <LayoutTemplate header={"Wählen Sie Ihre aktuelle Krankenkasse"}>
              <Select
                id="kkv"
                name="kkv"
                theme="light"
                onChange={val => {
                  setKKv(val)
                }}
              >
                <option value={""}>bitte wählen...</option>
                {props.kkv
                  .sort((a, b) => a.miamiName.localeCompare(b.miamiName))
                  .map((o, i) => (
                    <option key={i} value={o.id}>
                      {o.miamiName}
                    </option>
                  ))}
              </Select>
            </LayoutTemplate>
            <div className={styles.footer}>
              <button
                className={`btn outline inverted icon icon-only icon-prev-w ${styles.btn}`}
                onClick={handlePrevStep}
              />
              <button
                className={`btn block inverted icon icon-next icon-pos-rhs ${
                  styles.btn
                } ${props.buttonDisabled ? styles.btnDisabled : ""}`}
                onClick={handleNextStep}
                style={{ zIndex: "10" }}
              >
                Weiter
              </button>
            </div>
          </>
        )
      case 3:
        return (
          <ChooseOption
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            question="Wählen Sie Ihr Geschlecht"
            options={["Männlich", "Weiblich"]}
            images={[men, women]}
            onHandleChange={handleChange}
            currentBonus={currentBonus}
            buttonDisabled={disabled}
            canGoBack={true}
          />
        )
      case 4:
        return (
          <>
            {age >= 18 && (
              <ChooseOption
                onPrevStep={handlePrevStep}
                onNextStep={handleNextStep}
                question="Ich nutze die Generali Vitality App"
                options={["Ja", "Nein"]}
                optionalImage={generali}
                onHandleChange={handleChange}
                currentBonus={currentBonus}
                buttonDisabled={disabled}
              />
            )}
          </>
        )
      default:
        return (
          <>
            {activities && activities.length > 0 && (
              <>
                {activities
                  .filter(m => m.enabled)
                  .map((m, i) => {
                    if (step === 5 + i) {
                      return (
                        <Activities
                          key={m.id + (m.enabled ? "t" : "f")}
                          activity={m}
                          handlePrevStep={handlePrevStep}
                          handleNextStep={handleNextStep}
                          onHandleChange={handleChange}
                          currentBonus={currentBonus}
                          onChangeBmi={onChangeBmi}
                          informations={informations}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                {step >= 5 + activities.filter(a => a.enabled).length && (
                  <>
                    <div className={`${styles.innerCalc} ${styles.noMargin}`}>
                      <div
                        className={styles.left}
                        style={{ width: collapse ? "100%" : "60%" }}
                      >
                        <div className={styles.header}>
                          <div className={styles.textWrap}>
                            <h2 className={`h2 ${styles.h2}`}>
                              Ihr {resultCountDescriptor()} Fitbonus<sup>+</sup>{" "}
                              Ziel
                            </h2>
                          </div>
                          {collapse && (
                            <div className={styles.collapsed}>
                              <div className={styles.h}>
                                <p className={`h2 ${styles.bon}`}>Ihr Bonus</p>
                                <p className={styles.num}>
                                  {currentBonus
                                    .toFixed(2)
                                    .split(".")
                                    .join(",")}
                                  {" €"}
                                </p>
                              </div>
                              <button
                                onClick={() => {
                                  setCollapse(!collapse)
                                }}
                                className={styles.buttonDown}
                              />
                            </div>
                          )}
                        </div>
                        {!collapse && (
                          <>
                            <hr />
                            <div className={styles.tagsContainer}>
                              {activities.map((m, i) => {
                                if (!m.enabled) return null
                                return (
                                  <button
                                    key={i}
                                    className={styles.tag}
                                    onClick={() => {
                                      setStep(5 + i)
                                    }}
                                  >
                                    {m.name}
                                  </button>
                                )
                              })}
                            </div>

                            {props.additionalMemberCount < 4 && (
                              <button
                                className={`btn block icon ${styles.nextPerson}`}
                                onClick={() => {
                                  props.onHandleNewPerson()
                                  setCollapse(true)
                                }}
                              >
                                Weitere Person hinzufügen
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {!collapse && (
                        <Bonus
                          className={
                            step >= 2 + activities.filter(a => a.enabled).length
                              ? styles.rightEnd
                              : ""
                          }
                          value={currentBonus}
                        >
                          <button
                            className={styles.buttonUp}
                            onClick={() => {
                              setCollapse(!collapse)
                            }}
                          />
                        </Bonus>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )
    }
  }

  return (
    <section ref={props.elementRef} className={styles.calcSection}>
      {props.header && (
        <ContentContainer className={styles.headerContainer}>
          <div className={styles.bonus}>
            FitBonus<sup>+</sup> Rechner{" "}
            <span className={styles.head}>
              {" "}
              Berechnen Sie direkt Ihren persönlichen Bonus
            </span>
          </div>
        </ContentContainer>
      )}
      <div
        className={styles.bonusCalculator}
        // style={!collapse ? { minHeight: "544px" } : {}}
      >
        <ContentContainer>{allSteps()}</ContentContainer>
        {kkv !== null && (
          <div className={styles.kkvBonus}>
            <div>
              <div className={styles.text}>
                {kkvBonus > 0 && <>Ihre jährliche Beitragsersparnis zur </>}
                {kkvBonus < 0 && <>Ihre jährliche Differenz zur </>}
                {props.kkv.find(f => f.id === kkv).miamiName}
              </div>
              <div className={styles.hint}>{hint}</div>
            </div>
            <div className={styles.bonus}>
              {kkvBonus
                .toFixed(2)
                .split(".")
                .join(",")}
              {" €"}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

// let ersparnis;
// let bafög = 812;
// if (bkkLinde.zusatzbeitrag < fremdkasse.zusatzbeitrag) {
//   if (insuranceType === "Arbeitnehmer", "Azubi", "Rentner", "Arbeitslos") {
//     ersparnis = (brutto * (0.5 / 100 * fremdkasse.zusatzbeitrag)) - (brutto * (0.5 / 100 * bkkLinde.zusatzbeitrag))
//   } else if (insuranceType === "nicht Erwärbstätig", "selbstständig", "beamte") {
//     ersparnis = (brutto * fremdkasse.zusatzbeitrag / 100) - (brutto * bkkLinde.zusatzbeitrag / 100)
//   } else if (insuranceType === "studenten") {
//     ersparnis = (bafög * fremdkasse.zusatzbeitrag / 100) - (bafög * bkkLinde.zusatzbeitrag / 100);
//   }
// }
