import React from "react"

import * as styles from "./component.module.less"

export default props => {
  if (props.size && props.size === "small") {
    throw new Error('checkbox size "small" not supported')
  }

  return (
    <div
      className={`${styles.checkbox}${props.error ? " " + styles.error : ""}${
        props.className ? " " + props.className : ""
      }`}
    >
      <div className={styles.control}>
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          value={props.value || true}
          ref={props.formElementRef}
          defaultChecked={props.defaultChecked || undefined}
          disabled={props.disabled || false}
        />
        <span className={styles.act} />
      </div>
      {props.label && (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor={props.id}
            className={styles.lbl}
            dangerouslySetInnerHTML={{ __html: props.label }}
          />
        </>
      )}
    </div>
  )
}
