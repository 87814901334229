import React from "react"
import MyInsurance from "../layouts/my-insurance"
import { StaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"
import * as service from "../services/auth"

const Index = () => {

  if (!service.isLoggedIn()) {
    if(service.isBrowser()) {
      navigate("/login")
    }
    return null;
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <MyInsurance data={data} />
        )
      }}
    />
  )
}

export const query = graphql`
  query {
    allDatoCmsConfig(filter: { locale: { eq: "de" } }) {
      nodes {
        formMembership {
          slug
        }
      }
    }

    allDatoCmsLebensphasen(filter: { locale: { eq: "de" } }) {
      nodes {
        originalId
        name
        altersgruppe {
          id
        }
        versicherungsart {
          id
        }
        mitgliedschaftsart {
          id
        }
        leistungen {
          slug
          teasertext
          leistungsname
          leistungsart
          bilderDerLeistung {
            alt
            title
            gatsbyImageData(
              imgixParams: {
                auto: "format"
                w: "768"
                fit: "crop"
                h: "576"
                crop: "faces"
                dpr: 1.5
                q: 70
              }
            )
            fluid(
              imgixParams: {
                auto: "format"
                w: "768"
                fit: "crop"
                h: "576"
                crop: "faces"
                dpr: 1.5
                q: 70
              }
            ) {
              src
              base64
            }
          }
        }
      }
    }

    allDatoCmsVersicherungsart(
      sort: { fields: position, order: ASC }
      filter: { locale: { eq: "de" } }
    ) {
      nodes {
        id
        nameAuswahlMeineLeistungen
      }
    }


    allDatoCmsBeitragsmodul(filter: {locale: {eq: "de"}}) {
      nodes {
        id
        
        mbgKvPvJahr
        bbgKvPvJahr
        bafoegJahr
        bafoegMonat
        gehaltMinJahr
        gueltigAb
        gueltigBis
        
      }
    }
    allDatoCmsKkv(filter: {isActive: {eq: true}, locale: {eq: "de"}}) {
      nodes {
        id
        miamiId
        miamiName
        zusatzbeitrag
      }
    }

  }
`

export default Index
