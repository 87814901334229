import React from "react"
import {graphql, StaticQuery} from "gatsby";

import CheckboxCfe from "./../checkbox"
import FormCard from "../../form-card"

class Gdpr extends React.Component {

  // for backwards compat. only
  reset() {}

  render() {
    return (
        <StaticQuery
            query={graphql`
              query DataProtecttionPageConfigQuery {
                allDatoCmsConfig {
                  nodes {
                    dataProtectionPage {
                      slug
                    }
                  }
                }
              }
            `}
            render={data => {
              let error = this.props.error;

              if (error && error.message.length === 0) {
                switch(error.type) {
                  case 'required':
                    error.message = 'Diese Eingabe ist ein Pflichtfeld.';
                    break;
                  default:
                    error.message = 'Bitte überprüfen Sie ihre Eingabe.';
                }
              }

              let privacyUrl = "https://bkk-linde.de/datenschutz/";
              
              return (
                  <FormCard titleHtml="Zur Sicher&shy;heit Ihrer Daten">
                    <CheckboxCfe
                        id={this.props.id || 'gdpr'}
                        name={this.props.name || 'gdpr'}
                        value={this.props.value}
                        formElementRef={this.props.formElementRef}
                        error={this.props.error}
                        label={'Ja, ich habe die <a target="_blank" rel="noopener noreferrer" href="' + privacyUrl + '">Daten&shy;schutz&shy;erklärung</a> der BKK Linde gelesen und akzeptiere diese hiermit. *'}
                    />
                  </FormCard>
              );
            }}
        />
    );
  }
}

export default Gdpr;
