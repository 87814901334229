import React, { useState } from "react"
import { useForm } from "react-hook-form"
import ApiomatForm, { FieldRenderer, SalutationFieldRenderer } from "./../base"

import * as styles from "./component.module.less"

import GdprCfe from "../../elements/controls/gdpr"
import Checkbox from "../../elements/controls/checkbox"
import FormCard from "../../elements/form-card"
import axios from "axios"
import * as service from "../../../services/auth"

const OfferForm = props => {
  const refs = {
    insurancetype: React.createRef(),
    profession: React.createRef(),
    maritalstatus: React.createRef(),
    gender: React.createRef(),
    salutation: React.createRef(),
    reason: React.createRef(),
    incomePension: React.createRef(),
  }

  const { register, handleSubmit, errors, formState } = useForm()

  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [formData, setFormData] = useState({})
  const [offerType, setOfferType] = useState("prospect")

  let formConfig = null
  let context = null

  const onSubmit = async data => {
    setFormData(data)
    setSending(true)
    if (formState.isSubmitting) {
      return
    }
    const config = {
      headers: {
        "X-apiomat-system":
          process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_SYSTEMID,
        "X-apiomat-apikey": process.env.GATSBY_APIOMAT_MODULE_WEBSITEOFFER_KEY,
        "Content-Type": "application/json",
        "X-apiomat-fullupdate": "true",
        Authorization: "Bearer " + service.getUser().access_token,
      },
    }
    const type = { "@type": "Interessenten$1.0.0$Angebot" }
    let bonusData, familyData
    if (props.data.bonus.length >= 0) {
      bonusData = props.data.bonus[0]
      bonusData.age = props.data.age
      bonusData.ageID = props.data.ageID
      bonusData.birthday = props.data.birthday
      bonusData.customer = props.data.customer
      bonusData.occupation = props.data.occupation
      bonusData.offerType = offerType
      familyData = JSON.parse(JSON.stringify(props.data.bonus))
      familyData.shift()
      let keys = Object.keys(bonusData)
      keys.forEach(k => {
        if (Array.isArray(bonusData[k])) {
          let str
          bonusData[k].forEach((f, i) => {
            if (i === 0) {
              str = f
            } else {
              str = str + " | " + f
            }
          })
          bonusData[k] = str
        }
      })
    }
    let keys = Object.keys(data)
    keys.forEach(f => {
      if (data[f] === "true") {
        data[f] = true
      }
    })

    const postData = {
      ...data,
      ...type,
      dateOfBirth: props.data.birthday,
      phase: props.phaseId,
      bonus: {
        "@type": "Interessenten$1.0.0$Bonus",
        bonus: bonusData.bonus,
        bonusData: bonusData,
      },
      familyMembers: (familyData || []).map(m => {
        let keys = Object.keys(m)
        keys.forEach(k => {
          if (Array.isArray(m[k])) {
            let str
            m[k].forEach((f, i) => {
              if (i === 0) {
                str = f
              } else {
                str = str + " | " + f
              }
            })
            m[k] = str
          }
        })

        const { name, bonus, ...bonusData } = m
        return {
          "@type": "Interessenten$1.0.0$Bonus",
          name,
          bonus,
          bonusData,
        }
      }),
    }

    axios
      .post(
        "https://apiomatbm.bkk-linde.de/yambas/rest/apps/WebsiteAngebot/models/Interessenten/v/1.0.0/Angebot",
        postData,
        config
      )
      .then(() => {
        setSubmitted(true)
        setSending(false)
      })
      .catch(() => {
        const msg =
          "Zur Erstellung des Angebotes ist die Bonusberechnung notwendig."
        alert(msg)
        setSending(false)
      })
  }

  const onChangeRadioValue = event => {
    console.log("asfad")
    setOfferType(event.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <span>Aktion starten</span>
        <div className={styles.radio} onChange={onChangeRadioValue}>
          <div className={styles.rad}>
            <div className={styles.control}>
              <input type="radio" id="html" name="offerType" value="comeback" />
              <span className={styles.act} />
            </div>
            <label>Kündiger(in)</label>
          </div>
          <div className={styles.rad}>
            <div className={styles.control}>
              <input type="radio" id="css" name="offerType" value="prospect" defaultChecked={true} />
              <span className={styles.act} />
            </div>
            <label>Interessent(in)</label>
          </div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {!submitted && (
          <div className={styles.head}>
            <span className={styles.headline}>Jetzt Angebot erstellen</span>
            <span className={styles.tagline}>
              Erstellen Sie hier eine persönliche Angebots-PDF für folgenden
              Interessenten
            </span>
            <span className={styles.hint}>
              Alle mit Sternchen* gekennzeichneten Felder müssen korrekt
              ausgefüllt werden.
            </span>
            <span className={styles.formHead}>Kontaktdaten</span>
          </div>
        )}
        <ApiomatForm
          className={`${props.className || ""} ${
            formState.isSubmitting ? "loading" : ""
          }`}
          submitted={submitted}
          setSubmitted={setSubmitted}
          submittedTitle={"Ihr Antrag wurde gesendet."}
          backTitle="Neues Angebot erstellen"
          prevTitle="Angebot anpassen"
          onSubmit={(config, ctx, e) => {
            formConfig = config
            context = ctx
            handleSubmit(onSubmit)(e)
          }}
        >
          {SalutationFieldRenderer(
            props.prefix,
            "Anrede *",
            refs.salutation,
            register({ required: true }),
            errors.salutation,
            formData.salutation || ""
          )}
          {FieldRenderer(
            props.prefix,
            "title",
            "text",
            "Titel",
            'Geben Sie bitte Ihren Titel ein, z.B. "Dr." oder "Prof." (optional).',
            register,
            errors.title,
            formData.title || ""
          )}

          {FieldRenderer(
            props.prefix,
            "firstName",
            "text",
            "Vorname *",
            "Geben Sie bitte Ihren Vornamen ein.",
            register({ required: true }),
            errors.surname,
            formData.firstName || ""
          )}
          {FieldRenderer(
            props.prefix,
            "lastName",
            "text",
            "Nachname *",
            "Geben Sie bitte Ihren Nachnamen ein.",
            register({ required: true }),
            errors.lastname,
            formData.lastName || ""
          )}

          <div className={"fields"}>
            {FieldRenderer(
              props.prefix,
              "street",
              "text",
              "Strasse *",
              "Geben Sie bitte Ihre Strasse ein.",
              register({ required: true }),
              errors.street,
              formData.street || ""
            )}
            {FieldRenderer(
              props.prefix,
              "streetNr",
              "text",
              "Nr. *",
              "Geben Sie bitte Ihre Hausnummer ein.",
              register({ required: true }),
              errors.streetNr,
              formData.streetNr || ""
            )}
          </div>

          <div className={"fields"}>
            {FieldRenderer(
              props.prefix,
              "zip",
              "text",
              "PLZ *",
              "Geben Sie bitte Ihre PLZ ein.",
              register({ required: true }),
              errors.zip,
              formData.zip || "",
              10
            )}
            {FieldRenderer(
              props.prefix,
              "place",
              "text",
              "Ort *",
              "Geben Sie bitte Ihren Ort ein.",
              register({ required: true }),
              errors.place,
              formData.place || ""
            )}
          </div>

          {FieldRenderer(
            props.prefix,
            "email",
            "email",
            "E-Mail *",
            "Geben Sie bitte Ihre E-Mail Adresse ein.",
            register({ required: true, pattern: /^[^@]{1,}@[^@]{1,}$/ }),
            errors.email,
            formData.email || ""
          )}
          {FieldRenderer(
            props.prefix,
            "phone",
            "tel",
            "Telefon",
            "Geben Sie bitte Ihre Telefonnummer ein.",
            register,
            errors.phone,
            formData.phone || ""
          )}
          <FormCard titleHtml="Folgendermaßen an Interessenten versenden *">
            <div className={styles.checkBox}>
              <Checkbox
                label={"Post"}
                name={"sendMail"}
                error={errors.sendMail}
                formElementRef={register({ required: false })}
                disabled={true}
              />
              <Checkbox
                label={"E-Mail"}
                name={"sendEmail"}
                error={errors.sendEmail}
                formElementRef={register({ required: true })}
              />
            </div>
          </FormCard>
          <GdprCfe
            id={props.prefix + "-gdpr"}
            name={"gdpr"}
            formElementRef={register({ required: true })}
            error={errors.gdpr}
          />
          <button
            className={`${styles.submit} ${sending ? styles.sending : ""}`}
            disabled={sending}
            type="submit"
          >
            Angebot erstellen
          </button>
        </ApiomatForm>
      </div>
    </div>
  )
}

export default OfferForm
